<template>
  <AppLoginPageLayout switch-to-login>
    <div class="d-flex justify-center">
      <img
        src="@/assets/icons/logo_sourcery.svg">
    </div>
    <h1 class="form-login__container--title">
      Confirm your sign up
    </h1>
    <p
      v-if="!signUpBefore"
      class="form-login__container--description">
      We’ve sent a verification link to {{ getEmail }}. Please use it to validate your email.
    </p>
    <p
      v-else-if="codeResended"
      class="form-login__container--description">
      We’ve resent a verification link to {{ getEmail }}. Please use it to validate your email.
    </p>
    <p
      v-else
      class="form-login__container--description">
      We’ve sent a verification link to {{ getEmail }} before. Please check your email and use it to validate your email or resend link.
    </p>
    <v-form
      class="mt-0"
      @submit.prevent="confirmSignUp">
      <div
        v-for="(error, i) in verificationCodeErrors"
        :key="i"
        class="error--text text-center">
        {{ error }}
      </div>
      <p class="mt-7 medium-p d-flex justify-center">
        <a @click="resendCode">
          Resend link
        </a>
      </p>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import { Auth } from 'aws-amplify';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import { mapMutations } from 'vuex';
import ErrorsText from '@/constants/errors';
export default {
  name: 'ConfirmSignUp',
  components: {
    AppLoginPageLayout,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    signUpBefore: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorWithCode: null,
    codeResended: false,
    confirm: {
      code: null,
    },
  }),
  validations: {
    confirm: {
      code: {
        required, minLength: minLength(6),
      },
    },
  },
  computed: {
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.confirm.code.$dirty) return errors;
      if (!this.$v.confirm.code.minLength) {
        errors.push('Verification code must be at 6 characters long');
      }
      if (!this.$v.confirm.code.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorWithCode) {
        errors.push(this.errorWithCode);
      }
      return errors;
    },
    getEmail() {
      return this.email || this.$route.query.email;
    },
  },
  created() {
    const confirmationCode = this.$route.query.confirmation_code;
    if (!this.getEmail && !confirmationCode) {
      this.$router.push({
        name: 'sign-up',
      });
    }
    if (confirmationCode) {
      this.confirm.code = confirmationCode;
      this.confirmSignUp();
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapMutations({
      isSignUp: 'UserProfile/setUserSignUp',
    }),
    async confirmSignUp() {
      this.$v.confirm.$touch();
      if (!this.$v.confirm.$invalid) {
        this.spinner(true);
        const email = this.getEmail;
        const { code } = this.confirm;
        try {
          await Auth.confirmSignUp(email, code);
          this.isSignUp(true);
          this.$router.push({
            name: 'login', params: {
              email,
            },
          });
        } catch (err) {
          this.errorWithCode = err.message;
        } finally {
          this.spinner(false);
        }
      }
    },
    async resendCode() {
      this.spinner(true);
      try {
        await Auth.resendSignUp(this.getEmail);
        this.codeResended = true;
      } catch (err) {
        this.errorWithCode = err.message;
        console.log('err', err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
