<template>
  <v-col class="pa-0 social-container">
    <p
      class="social-container__separator mainGrey--text"
      style="font-size: 14px">
      OR
    </p>
    <p
      class="medium-p text-center mainGrey--text">
      Log In using social networks
    </p>
    <v-row
      class="ma-auto mt-6"
      justify="center"
      style="max-width: 100px">
      <div
        class="social-container__icon pointer"
        @click="signInGoogle">
        <img
          src="@/assets/icons/google.svg">
      </div>
      <!-- <div class="social-container__icon ml-3">
        <img
          src="@/assets/icons/instagram.svg"
        />
        </div> -->
    </v-row>
  </v-col>
</template>
<script>
import { Auth } from 'aws-amplify';
import { mapMutations } from 'vuex';
export default {
  name: 'LoginUsingSocial',
  methods: {
    ...mapMutations(['spinner']),
    async signInGoogle() {
      try {
        this.spinner(true);
        await Auth.federatedSignIn({
          provider: 'Google',
        });
      } catch (err) {
        this.spinner(false);
        console.log('err feder', err);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.social-container {
  &__icon {
    height: 40px;
    width: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &:focus-within {
      background: var(--v-mainBlue-base);
      img {
        filter: invert(1);
      }
    }
  }
  padding-bottom: 50px;
  margin-top: 60px;
  &__separator {
    display: flex;
    align-items: center;
    text-align: center;
    &::before, &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #BAC2C4;
    }
    &::before {
      margin-right: 12px;
    }
    &::after {
      margin-left: 12px;
    }
  }
}
</style>
