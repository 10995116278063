<template>
  <v-row
    class="ma-0 double-container"
    :class="`double-container__${$route.name}`">
    <v-col
      class="pa-0 white-part"
      md="5"
      cols="12" />
    <v-col
      class="pa-0 darkGrey-part d-flex align-center"
      md="7"
      cols="12">
      <v-row
        class="ma-auto d-flex flex-column"
        style="max-width: 340px;">
        <h1
          class="lightBlue--text"
          style="text-align: center">
          {{ switchToLogin ? 'Already' : 'New' }}
          <span style="color: #fff">
            {{ switchToLogin ? 'With Us?' : 'Here?' }}
          </span>
        </h1>
        <p
          class="mt-3 text-center"
          style="color: #fff">
          Join us in creating a social collaborative platform for construction industry professionals
          to seamlessly connect with one another and the supply chain.
        </p>
        <v-btn
          :to="{ name: path }"
          class="ma-auto mt-10"
          :data-test="switchToLogin ? 'switch_to_log_in_button' : 'switch_to_sign_up_button'"
          color="lightBlue">
          {{ switchToLogin? 'LOG IN' : 'SIGN UP' }}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'LoginDoubleParts',
  props: {
    switchToLogin: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    path() {
      return this.isEvent
        ? this.switchToLogin ? 'event-login' : 'event-sign-up'
        : this.switchToLogin ? 'login' : 'sign-up';
    },
  },
};
</script>
<style scoped lang="scss">
  .double-container {
    height: 100vh;
    @media only screen and (max-height: 880px)  {
        height: 879px;
    }
    &__sign-up, &__event-sign-up {
      flex-direction: row-reverse;
    }
    .white-part {
      @media only screen and (max-width: 959px)  {
        display: none;
      }
    }
    .blue-part {
      background: #212946;
    }
    .darkGrey-part {
      background:var(--v-darkGrey-base);
    }
  }
</style>
