<template>
  <div
    class="form-login">
    <LoginDoubleParts
      v-bind="$attrs"
      :is-event="isEvent" />
    <div
      class="form-login__container"
      :class="{'signup': isSignUpPage}">
      <slot />
      <LoginUsingSocial v-if="showLoginUsingSocial" />
      <slot name="footer-image" />
    </div>
  </div>
</template>
<script>
import LoginDoubleParts from './LoginDoubleParts';
import LoginUsingSocial from './LoginUsingSocial';
export default {
  name: 'AppLoginPageLayout',
  components: {
    LoginDoubleParts,
    LoginUsingSocial,
  },
  props: {
    isEvent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLoginUsingSocial() {
      return ['sign-up', 'login', 'event-login', 'event-sign-up'].includes(this.$route.name);
    },
    isSignUpPage() {
      return ['sign-up', 'event-sign-up'].includes(this.$route.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-login__container.signup,
.form-login__container.event-sign-up {
  margin-left: 47%;
  @media only screen and (max-width: 1255px)  {
    margin-left: 58%;
  }
}
</style>
